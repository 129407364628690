import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

export default function CardComponent({ title, image }) {
  return (
    <Card sx={{ maxWidth: 550 }}>
      <CardMedia
        sx={{ height: 140 }}
        image={image}
        title={title}
        loading="lazy"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
      </CardContent>
      <CardActions>
        <Link to="/servicesingle">Lire plus</Link>
      </CardActions>
    </Card>
  );
}
