import React from "react";

const ServiceComponent = ({ title, image1, image2, children }) => {
  return (
    <div className="container my-5 mb-5">
      <div
        className="row p-4"
        style={{
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          backgroundColor: "#fff",
        }}
      >
        {/* Colonne Titre et Contenu */}
        <div className="col-md-6">
          {/* Titre du service */}
          <h2
            className="font-weight-bold"
            style={{ fontSize: "1.75rem", fontWeight: "bold" }}
          >
            {title}
          </h2>
          {/* Texte descriptif via children */}
          <div className="mt-4" style={{ listStyleType: "disc" }}>
            {children}
          </div>
        </div>
        {/* Colonne Images */}
        <div className="col-md-6 d-flex flex-column align-items-center">
          <img
            src={image1}
            alt="Illustration 1"
            className="img-fluid mb-4"
            style={{ maxWidth: "80%", height: "auto" }}
            loading="lazy"
          />
          <img
            src={image2}
            alt="Illustration 2"
            className="img-fluid"
            style={{ maxWidth: "80%", height: "auto" }}
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceComponent;
