import React from "react";

// components
import Navbar from "../../components/Navbar";
import Breadcumb from "../../components/breadcumb";
import AboutSection2 from "../../components/about2";
import TeamSection from "../../components/Team";
import FooterSection from "../../components/Footer";

const Aboutpage = () => {
  return (
    <div>
      <Navbar />
      <Breadcumb bdtitle={""} bdsub={""} />
      <AboutSection2 />
      <TeamSection />
      <FooterSection />
    </div>
  );
};

export default Aboutpage;
