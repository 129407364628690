import React from "react";
import "./style.css";
import CardComponent from "../card/CardComponent";
import cabotage from "../../images/service/cabotage.png";
import levage from "../../images/service/levage.png";
import transport from "../../images/service/transport-maritime.jpg";
import logistic from "../../images/service/logistique-vehicule.png";
import disposition from "../../images/service/mise-a-disposition.png";
import tmp from "../../images/service/fabrique.png";
import sogapibs from "../../images/service/sogapibs.png";
import genie from "../../images/service/genie-civile.png";

const ServiceArea = () => {
  const services = [
    { id: 1, title: "Logistique Maritime et Cabotage", image: cabotage },
    { id: 2, title: "Levage & Manutention", image: levage },
    { id: 3, title: "Transport Terrestre", image: transport },
    { id: 4, title: "Logistique Véhicule Légers", image: logistic },
    { id: 5, title: "Mise à disposition d'espaces", image: disposition },
    { id: 6, title: "TPM", image: tmp },
    { id: 7, title: "SOGAPIBS", image: sogapibs },
    { id: 8, title: "Génie civile", image: genie },
  ];

  return (
    <div className="wpo-service-s2">
      <div className="container">
        <div className="wpo-section-title text-center">
          <h2>Nos services</h2>
        </div>
        <div className="row">
          {services.map((service) => (
            <div key={service.id} className="col-lg-3 col-md-6 col-12 mb-4">
              <CardComponent title={service.title} image={service.image} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceArea;
