import React from "react";

// components
import Navbar from "../../components/Navbar";
import Breadcumb from "../../components/breadcumb";
import ServiceArea2 from "../../components/ServiceArea2";
import FooterSection from "../../components/Footer";

const ServiceSingle = () => {
  return (
    <div>
      <Navbar />
      <Breadcumb bdtitle={""} bdsub={""} />
      <ServiceArea2 />
      <FooterSection />
    </div>
  );
};

export default ServiceSingle;
