import React from "react";
import ServiceComponent from "../nosServices/ServiceComponent";
import cabotage1 from "../../images/service/cabotage.png";
import cabotage2 from "../../images/service/cabotage2.png";
import levage1 from "../../images/service/levage.png";
import levage2 from "../../images/service/levage2.png";
import transport from "../../images/service/transport-terrestre.png";
import transport2 from "../../images/service/transport-terrestre2.png";
import logistic from "../../images/service/logistique-vehicule.png";
import logistic2 from "../../images/service/logistique-vehicule3.png";
import disposition from "../../images/service/disposition-espace.png";
import disposition2 from "../../images/service/mise-a-disposition3.png";
import sogapibs from "../../images/service/sogapibs.png";
import sogapibs2 from "../../images/service/sogabips2.png";
import genie from "../../images/service/genie-civile.png";
import genie2 from "../../images/service/genie-civile2.png";
import maritime from "../../images/service/fabrique.png";
import maritime2 from "../../images/service/fabrique2.png";

const ServiceArea = (props) => {
  return (
    <div className="wpo-service-s2">
      <div className="container">
        <div className="col-l2">
          <div className="wpo-section-title text-center">
            {/* <span>Nous vous fournissons le meilleur</span> */}
            <h2>Nos services</h2>
          </div>
        </div>
        <div className="row">
          <ServiceComponent
            title="Logistique Maritime et Cabotage National et International"
            image1={cabotage1}
            image2={cabotage2}
          >
            <p>
              A-LOGIS assure gère une ligne régulière de transport maritime de
              marchandises entre :
            </p>
            <ul>
              <li>- Port Gentil et Libreville d’une part ;</li>
              <li>- Libreville et Douala (Cameroun) d’autre part.</li>
            </ul>
            <p>
              A-LOGIS dispose dans ce sens d’une flotte maritime constituée de :
            </p>
            <ul>
              <li>- LCT</li>
              <li>- Barges</li>
              <li>- Pousseurs</li>
              <li>- Remorqueurs</li>
              <li>- Porte containers</li>
            </ul>
            <p>
              En dehors de ces lignes régulières, A-LOGIS peut affréter ses
              navires pour un transport fluvial et maritime de tous types de
              marchandises.
            </p>
          </ServiceComponent>
          <ServiceComponent
            title="Levage & Manutention"
            image1={levage1}
            image2={levage2}
          >
            <p>
              A-LOGIS dispose d’un important parc de matériel de levage et de
              manutention tel que :
            </p>
            <ul>
              <li>- Grues de diverses capacités allant de 30 T à 160 T ;</li>
              <li>
                - Chariots élévateurs de diverses capacités allant de 2.5 T à 45
                T (portes containers) ;
              </li>
              <li>- Tracteurs avec grues auxiliaires.</li>
            </ul>
          </ServiceComponent>
          <ServiceComponent
            title="Transport Terrestre "
            image1={transport}
            image2={transport2}
          >
            <p>. A-LOGIS dispose d’un parc important composé de :</p>
            <ul>
              <li>- Tracteurs (4x2, 4x4, 6X4, 6X6, 8X8)</li>
              <li>- Camions plateaux ;</li>
              <li>- Tracteurs et camions plateaux avec grues auxiliaires ;</li>
              <li>- Remorques 40 T et 50 T.</li>
              <li>- Portes chars de 70 à 80 T.</li>
            </ul>
            <p>
              Pour satisfaire les besoins de transport de nos clients, A-LOGIS
              propose :
            </p>
            <ul>
              <li>
                - La mise à disposition de tracteurs et de remorques permanents
                pour les besoins récurrents ;
              </li>
              <li>
                - Le recours à son parc logistique disponible à Port Gentil,
                Libreville, Gamba et Mouanda pour les livraisons et les
                approvisionnements occasionnels.
              </li>
            </ul>
          </ServiceComponent>
          <ServiceComponent
            title="Logistique Véhicule Légers"
            image1={logistic}
            image2={logistic2}
          >
            <p>
              A-LOGIS est un acteur majeur de la location de véhicules légers au
              Gabon et opère dans les zones de Libreville, Port Gentil, Mouanda
              A-LOGIS dispose d’un parc de véhicules légers composés de :
            </p>
            <ul>
              <li>- Citadines, moyennes et grandes berlines ;</li>
              <li>- Véhicules utilitaires (fourgons...)</li>
              <li>- Bus de différentes capacités ;</li>
              <li>- Pick up 4X4 ;</li>
              <li>- Véhicules 4X4 petit modèle, grand modèle et de luxe.</li>
            </ul>
            <p>
              Pour toutes catégories A-LOGIS propose des prestations sous la
              forme de contrat longue durée ou de location courte durée. Dans
              ses contrats, A-LOGIS assure la maintenance et garantit la
              disponibilité des véhicules 24h/24 et 7j/7. Un système
              d’assistance est garantie 24h/24 et 7j/7. Ses prestations
              permettent aux clients de sortir de la gestion courante des
              véhicules et d’assurer la disponibilité de façon continue.
            </p>
          </ServiceComponent>
          <ServiceComponent
            title="La Mise a disposition d’espaces et d’infrastructures"
            image1={disposition}
            image2={disposition2}
          >
            <p>
              A-LOGIS met à la disposition de ses clients des infrastructures
              pour le support de leurs activités de plus 150 000 m². Ces
              infrastructures sont constituées de :
            </p>
            <ul>
              <li>- bureaux;</li>
              <li>- entrepôts de stockage</li>
              <li>- yards de stockage</li>
              <li>- postes à quai pour l’accostage des navires</li>
              <li>- containers bureaux et de stockage.</li>
            </ul>
          </ServiceComponent>
          <ServiceComponent title="TPM" image1={maritime} image2={maritime2}>
            <p>
              Fabrication mécanique : usinage, filetage sur tubulaires
              pétroliers, réparation d’hélices de bateaux…
            </p>
            <p>Fabrication métallique :  réfection de citernes, bennes,</p>
            <p>
              remorques, construction de hangars, construction et réparation
              navale, réalisation et pose de circuits de tuyauterie, travaux de
              pliage, découpage et roulage de différents corps
              métalliques, travaux de soudure générale et spécialisée,
              réalisation de charpente métallique
            </p>
          </ServiceComponent>
          <ServiceComponent
            title="Sogapibs"
            image1={sogapibs}
            image2={sogapibs2}
          >
            <p>
              Ce service s’occupe du sablage et peinture des structures
              métallique en utilisant un processus spécifique :
            </p>
            <ul>
              <li>- Décapage à sec par jet abrasif</li>
              <li>- Décapage par grenaillage</li>
              <li>
                - Application des peintures de haute qualité, spécialement
                conçues pour résister aux intempéries et aux UV
              </li>
            </ul>
          </ServiceComponent>
          <ServiceComponent
            title="Génie Civile "
            image1={genie}
            image2={genie2}
          >
            <p>
              Notre expertise en génie civil couvre une gamme de services allant
              de la conception de structures à la réalisation (quai, bâtiment,
              structure métallique). Nous avons une carrière de latérite
              nécessaire à l’aménagement des voiries, yard et terrassement.
              Ainsi qu’une drague de sable
            </p>
          </ServiceComponent>
        </div>
      </div>
    </div>
  );
};

export default ServiceArea;
