import React from "react";
import ContactForm2 from "../CommentForm2";

import "./style.css";

const Contactpage = () => {
  return (
    <div className="contact-page-area section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-12">
            <div className="contact-page-item">
              <h2>Nos coordonnées</h2>
              {/* <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classNameical Latin literature from 45 BC, making it over 2000 years old.</p> */}
              <div className="adress">
                <h3>Adresse</h3>
                <span>Acae, Libreville, Gabon</span>
              </div>
              <div className="phone">
                <h3>N° de téléphone</h3>
                <span>+241 011 70 13 94</span>
              </div>
              <div className="email">
                <h3>Email</h3>
                <span>info@alogisgabon.com</span>
              </div>
              <div className="email">
                <h3>BP</h3>
                <span>3926</span>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-12">
            <div className="contact-area contact-area-2 contact-area-3">
              <h2>Formulaire de contact</h2>
              <ContactForm2 />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactpage;
