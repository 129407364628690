import React from "react";

// components
import Navbar from "../../components/Navbar";
import SimpleSlider from "../../components/hero";
import AboutSection from "../../components/about";
import ServiceArea from "../../components/ServiceArea";
import CounterSection from "../../components/CounterSection";
import TeamSection from "../../components/Team";
import FooterSection from "../../components/Footer";
import CharterComponent from "../../components/chartre/Chartre";

const Homepage = () => {
  return (
    <div>
      <Navbar />
      <SimpleSlider />
      <AboutSection />
      <CharterComponent />
      <CounterSection />
      <ServiceArea />
      <TeamSection />
      <FooterSection />
    </div>
  );
};

export default Homepage;
