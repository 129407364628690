import React from "react";
import { Link } from "react-router-dom";

import "./style.css";

const AboutSection2 = () => {
  return (
    <div className="wpo-about-style-2">
      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-6  offset-lg-6 about-wrap">
            <div className="wpo-about-content">
              <div className="wpo-about-icon">
                <i className="fi flaticon-travel"></i>
              </div>
              <h2>Qui sommes nous?</h2>
              <p>
                A-LOGIS est un prestataire de logistique intégrée au service de
                l’industrie. Qui a pour actionnaire le groupe SATRAM-EGCA. Elle
                dispose de systèmes et moyens logistiques très performants, de
                ressources humaines hautement qualifiées et d’une gamme étendue
                et adaptée de matériels aptes à offrir des solutions complètes à
                toutes les situations.
              </p>
              <p>
                la société a su développer une gamme complète de services
                parfaitement adaptés aux besoins du marché régional et conformes
                aux standards internationaux. Ses interventions ciblées dans les
                achats, l’entretien et la maintenance de ses équipements,
                toujours de plus en plus innovants, lui permettent de répondre
                aux critères les plus exigeants définis dans les cahiers des
                charges de ses clients de référence tels que l’industrie minière
                ,pétrolière, les entités étatiques et para étatiques, les
                compagnies maritimes et le génie civile .
              </p>
              <Link to="/about" className="savoir-plus">
                En savoir plus
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection2;
