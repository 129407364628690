import React from 'react';
import './style.css'
const ServiceArea3 = ( ) => {

    return(
        <div className="service-style-3">
            <div className="container">
                <div className="col-l2">
                    <div className="wpo-section-title text-center">
                        <span>We Provide the Best</span>
                        <h2>Our Service</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-icon">
                                <i className="fi flaticon-truck"></i>
                            </div>
                            <div className="service-text">
                                <h3>Land Transport</h3>
                                <p>There are many variations of passages of Lorem Ipsum</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-icon">
                                <i className="fi flaticon-ship-1"></i>
                            </div>
                            <div className="service-text">
                                <h3>Sea Freight</h3>
                                <p>There are many variations of passages of Lorem Ipsum</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-icon">
                                <i className="fi flaticon-plane"></i>
                            </div>
                            <div className="service-text">
                                <h3>Air Freight</h3>
                                <p>There are many variations of passages of Lorem Ipsum</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-icon">
                                <i className="fi flaticon-construction-tool-vehicle-with-crane-lifting-materials"></i>
                            </div>
                            <div className="service-text">
                                <h3>Logistic Solutions</h3>
                                <p>There are many variations of passages of Lorem Ipsum</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-icon">
                                <i className="fi flaticon-box"></i>
                            </div>
                            <div className="service-text">
                                <h3>Packaging and Store</h3>
                                <p>There are many variations of passages of Lorem Ipsum</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-icon">
                                <i className="fi flaticon-trolley"></i>
                            </div>
                            <div className="service-text">
                                <h3>Warehousing</h3>
                                <p>There are many variations of passages of Lorem Ipsum</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default ServiceArea3;
