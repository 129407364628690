import React from "react";
import { Link } from "react-router-dom";

import "./style.css";

const CounterSection = (props) => {
  return (
    <div className={`wpo-counter-area ${props.subclass}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-6 col-sm-12">
            <div className="wpo-counter-content">
              <h2>Pourquoi nous choisir ?</h2>
              <p>
                Au Gabon et dans le golfe de Guinée, A-LOGIS se crée une
                réputation grâce notamment à son impressionnante flotte d’engins
                de manutention, de véhicules et de navires qui lui permet
                d’assister ses clients dans toutes leurs opérations.
              </p>
              <p>
                Avec ses équipements qui jouissent d’un savoir-faire de plus de
                30 ans dans le domaine de la logistique. A-LOGIS offre des
                solutions logistiques innovantes, adaptées à la sous-région.
              </p>
              <div className="btns">
                <div className="btn-style btn-style-3">
                  <Link to="/about">En savoir plus...</Link>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-5 col-md-6 col-sm-12">
            <div className="wpo-counter-grids">
              <div className="grid">
                <div>
                  <h2>
                    <span>4,012</span>
                  </h2>
                </div>
                <p>Delivered Packages</p>
              </div>
              <div className="grid">
                <div>
                  <h2>
                    <span>605</span>
                  </h2>
                </div>
                <p>Countries Covered</p>
              </div>
              <div className="grid">
                <div>
                  <h2>
                    <span>920</span>
                  </h2>
                </div>
                <p>Satisfied Clients</p>
              </div>
              <div className="grid">
                <div>
                  <h2>
                    <span>3,592</span>
                  </h2>
                </div>
                <p>Tons of Goods</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CounterSection;
