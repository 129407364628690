import React, { Component } from "react";
import "./style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import team_1 from "../../images/service/transport-terrestre2.png";
import team_2 from "../../images/about/dorade.jpg";
import team_3 from "../../images/service/genie-civile2.png";

class TeamSection extends Component {
  render() {
    return (
      <div className="wpo-team-area-2">
        <div className="container">
          <div className="col-l2">
            <div className="wpo-section-title text-center">
              {/* <span>Nous sommes à votre service</span> */}
              <h2>Notre savoir faire</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="wpo-team-single">
                <div className="wpo-team-img">
                  <img src={team_1} alt="" loading="lazy" />
                </div>
                <div className="wpo-team-content">
                  <h4>Terrestre</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="wpo-team-single">
                <div className="wpo-team-img">
                  <img src={team_2} alt="" loading="lazy" />
                </div>
                <div className="wpo-team-content">
                  <h4>Maritime</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="wpo-team-single">
                <div className="wpo-team-img">
                  <img src={team_3} alt="" loading="lazy" />
                </div>
                <div className="wpo-team-content">
                  <h4>Génie civile</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TeamSection;
