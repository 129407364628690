import React from "react";
import chartre from "../../images/chartre/charte-alogi.png";

const CharterComponent = () => {
  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <h2 className="text-center">Nos valeurs</h2>
        <div className="col-md-12 text-center">
          {/* Affichage de l'image */}
          <img
            src={chartre}
            alt="Charte ALOGIS"
            className="img-fluid"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
      </div>
    </div>
  );
};

export default CharterComponent;
