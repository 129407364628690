import React, { Component } from "react";

class ContactForm2 extends Component {
  state = {
    nom: "",
    email: "",
    telephone: "",
    adresse: "",
    notes: "",
    error: {},
  };

  changeHandler = (e) => {
    const error = this.state.error;
    error[e.target.name] = "";

    this.setState({
      [e.target.name]: e.target.value,
      error,
    });
  };

  subimtHandler = (e) => {
    e.preventDefault();

    const { nom, email, telephone, adresse, notes, error } = this.state;

    if (nom === "") {
      error.nom = "Please enter your name";
    }
    if (email === "") {
      error.email = "Please enter your email";
    }
    if (telephone === "") {
      error.telephone = "Please enter your number";
    }
    if (adresse === "") {
      error.adresse = "Please enter your adress";
    }
    if (notes === "") {
      error.notes = "Please enter your note";
    }

    if (error) {
      this.setState({
        error,
      });
    }
    if (
      error.name === "" &&
      error.email === "" &&
      error.email === "" &&
      error.number === "" &&
      error.adress === "" &&
      error.notes === ""
    ) {
      this.setState({
        nom: "",
        email: "",
        telephone: "",
        adresse: "",
        notes: "",
        error: {},
      });
    }
  };

  render() {
    const { nom, email, telephone, adresse, notes, error } = this.state;

    return (
      <form onSubmit={this.subimtHandler}>
        <div className="contact-form form-style row">
          <div className="col-12 col-lg-6">
            <input
              type="text"
              value={nom}
              onChange={this.changeHandler}
              placeholder="Votre nom*"
              id="fname"
              name="nom"
            />
            <p>{error.nom ? error.nom : ""}</p>
          </div>
          <div className="col-12  col-lg-6">
            <input
              type="email"
              placeholder="Votre email"
              onChange={this.changeHandler}
              value={email}
              id="email"
              name="email"
            />
            <p>{error.email ? error.email : ""}</p>
          </div>
          <div className="col col-lg-6">
            <input
              type="text"
              placeholder="N° de téléphone"
              onChange={this.changeHandler}
              value={telephone}
              id="number"
              name="telephone"
            />
            <p>{error.telephone ? error.telephone : ""}</p>
          </div>
          <div className="col-12  col-lg-6">
            <input
              type="adress"
              placeholder="Votre adresse"
              onChange={this.changeHandler}
              value={adresse}
              id="adress"
              name="adresse"
            />
            <p>{error.adresse ? error.adresse : ""}</p>
          </div>
          <div className="col-12 col-sm-12">
            <textarea
              className="contact-textarea"
              value={notes}
              onChange={this.changeHandler}
              placeholder="Message"
              name="notes"
            ></textarea>
            <p>{error.notes ? error.notes : ""}</p>
          </div>
          <div className="col-12">
            <button type="submit" className="theme-btn">
              Envoyer Message
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default ContactForm2;
