import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo/logo3.png";

import "./style.css";

const FooterSection = () => {
  return (
    <div className="wpo-footer-area">
      <div className="wpo-footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6 footer-t">
              <div className="wpo-footer-logo">
                <img src={logo} alt="" />
              </div>
              <div className="social">
                <ul className="d-flex">
                  <li>
                    <Link to="/">
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-9 col-md-6 col-sm-6 footer-t">
              <div className="footer-link">
                <h3>Liens utiles</h3>
                <ul>
                  <li>
                    <Link to="/about">A propos</Link>
                  </li>
                  <li>
                    <Link to="/servicesingle">Service</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-6 col-sm-6 footer-b">
              <div className="Recent-News-area">
                <h3>Actualités récentes</h3>
                <div className="resent-sub">
                  <p>There are many variations of passages of Lorem</p>
                  <span>
                    <i className="fa fa-clock-o" aria-hidden="true"></i> Octobor
                    10, 2018
                  </span>
                </div>
                <p>There are many variations of passages of Lorem</p>
                <span>
                  <i className="fa fa-clock-o" aria-hidden="true"></i> Octobor
                  10, 2018
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12">
              <div className="footer-widget instagram">
                <h3>Instagram</h3>
                <ul className="d-flex">
                  <li>
                    <Link to="/">
                      <img src={in1} alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img src={in2} alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img src={in3} alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img src={in4} alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img src={in5} alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img src={in6} alt="" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="wpo-footer-bottom">
        <div className="container">
          <div className="wpo-footer-bottom-content">
            <div className="row">
              <div className="col-12">
                <span>Développé par Axe technologies.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
