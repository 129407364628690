import React from "react";
import abimg1 from "../../images/service/apropos.jpg";
// import abimg2 from "../../images/about/1.png"
import "./style.css";

const AboutSection = () => {
  return (
    <div className="wpo-about-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="wpo-about-img">
              <img src={abimg1} alt="" loading="lazy" />
            </div>
          </div>
          <div className="col-lg-6 sec-p">
            <div className="wpo-about-content">
              <div className="wpo-about-icon">
                <i className="fi flaticon-travel"></i>
              </div>
              <h2>Qui sommes nous ?</h2>
              <p>
                A-LOGIS est un prestataire de logistique intégrée au service de
                l’industrie. Qui a pour actionnaire le groupe SATRAM-EGCA. Elle
                dispose de systèmes et moyens logistiques très performants, de
                ressources humaines hautement qualifiées et d’une gamme étendue
                et adaptée de matériels aptes à offrir des solutions complètes à
                toutes les situations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
